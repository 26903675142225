

import { DataTableHeader } from "vuetify"
import Vue from "vue"
import { format, parseISO } from "date-fns"
const dateCreated = function(dtCreated: string): string {
	const dateObject = parseISO(dtCreated)
	return format(dateObject, "EEEE dd/MM yyyy '-' hh:mm")
}
import {GetAccountDetails, AccountItem} from "@/types/responses"
import { TableMode} from "@/types/enums"
export default Vue.extend({
	name: "AccountResult",
	props: {
		tableMode: Object as () => TableMode
	},
	data(): {
		search: string
		updateMessage: string
		searching: boolean
		selectedItem: null | GetAccountDetails
		loading: boolean
		headers: DataTableHeader[]
		snackBar: boolean
		snackBarText: string
		details: boolean
		removing: boolean
		companySearch: string
		
	} {
		return {
			search: "",
			updateMessage: "",
			searching: true,
			selectedItem: null,
			loading: false,
			details: false,
			removing: false,
			headers: [
				{ text: "Name", value: "name", sortable: true, filterable: true },
				{ text: "User Id", value: "userId", sortable: true, filterable: true },
				{ text: "Email", value: "emailAddress", sortable: true, filterable: true },
				{ text: "Number", value: "phoneNumber", sortable: false, filterable: true},
				{ text: "Company", value: "organizations[0].name", sortable: true, filterable: true },
				{ text: "Action", value: "action", sortable: false, filterable: false },
			],
			snackBar: false,
			snackBarText: "",
			companySearch: "",
			}
	},
	computed: {

		getAccountResults(): AccountItem[] {
			return this.$store.state.context.findPersonAccounts
		}
	},

	methods: {
		openRemoveDialog(item: AccountItem): void {
			if(item){
				this.$vStore.dispatch("context/setSelectedAccount", item)
				this.details = false
				this.removing = true
				this.$emit("openRemoveDialog", item)
			}
		},
		openDetailsDialog(item: AccountItem): void{
			if(item){
				this.$vStore.dispatch("context/setSelectedAccount", item)
				this.details = true
				this.removing = false
				this.$emit("openDetailsDialog", item)
			}
		},
		clearSearch(): void {
			this.search = ""
		},		
		showSnackBar(childMsg: string): void {
			this.snackBarText = childMsg
			this.snackBar = true
		},
	},
})
